/* Login Page */

.login-wrapper {
    background: #6200EE;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-logo {
    width: 75px;
    height: auto;
    padding: 20px;
}

.login-title {
    color: #000000;
}

.login-card {
    position: relative;
    padding: 20px 0px 50px;
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
}

.login-button {
    cursor: pointer;
    color: white;
    padding: 14px;
    border-radius: 4px;
}

.login-button:hover {
    opacity: 80%;
    transition: 0.25s;
}

.facebook {
    background-color: #3b5998;
}

.google {
    background-color: #4285f4;
}

#loading-container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255, 0.22);
}

#loading-icon {
    font-size: 66px;
    position: relative;
    left: calc(50vw - 33px);
    top: calc(50vh - 22px);
}
