* {
    font-family: 'Questrial', sans-serif;
}

h1 {
    font-size: 96;
    font-weight: 200;
    letter-spacing: -1.5;
}

h2 {
    font-size: 60;
    font-weight: 200;
    letter-spacing: -0.5;
}

h3 {
    font-size: 48;
    font-weight: 400;
    letter-spacing: 0;
}

h4 {
    font-size: 34;
    font-weight: 400;
    letter-spacing: 0.25;
}

h5 {
    font-size: 24;
    font-weight: 400;
    letter-spacing: 0;
}

h6 {
    font-size: 20;
    font-weight: 500;
    letter-spacing: 0.15;
}

subtitle {
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.15;
}

body {
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.5;
}

button {
    font-size: 14;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.25;
}

caption {
    font-size: 12;
    font-weight: 400;
    letter-spacing: 0.4;
}

overline {
    font-size: 10;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5;
}