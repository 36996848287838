@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);


* {
    font-family: 'Questrial', sans-serif;
}

h1 {
    font-size: 96;
    font-weight: 200;
    letter-spacing: -1.5;
}

h2 {
    font-size: 60;
    font-weight: 200;
    letter-spacing: -0.5;
}

h3 {
    font-size: 48;
    font-weight: 400;
    letter-spacing: 0;
}

h4 {
    font-size: 34;
    font-weight: 400;
    letter-spacing: 0.25;
}

h5 {
    font-size: 24;
    font-weight: 400;
    letter-spacing: 0;
}

h6 {
    font-size: 20;
    font-weight: 500;
    letter-spacing: 0.15;
}

subtitle {
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.15;
}

body {
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.5;
}

button {
    font-size: 14;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.25;
}

caption {
    font-size: 12;
    font-weight: 400;
    letter-spacing: 0.4;
}

overline {
    font-size: 10;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5;
}
/* Chats Page */

.chats-page {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.nav-bar {
    background-color: #6200EE;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 66px;
    padding: 0px 40px;
}

.logo-tab {
    color: #FFFFFF;
}

.logout-tab {
    color: #FFFFFF;
    cursor: pointer;
}

.logout-button {
    padding: 14px 40px;
    background-color: transparent;
    color: #FFFFFF;
    border: 0px;
    border-radius: 4px;
    cursor: pointer;
}

.logout-text {
    margin-left: 4px;
}

.logout-button:hover {
    opacity: 80%;
    transition: 0.25s;
}

::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Login Page */

.login-wrapper {
    background: #6200EE;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.login-logo {
    width: 75px;
    height: auto;
    padding: 20px;
}

.login-title {
    color: #000000;
}

.login-card {
    position: relative;
    padding: 20px 0px 50px;
    width: 420px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
}

.login-button {
    cursor: pointer;
    color: white;
    padding: 14px;
    border-radius: 4px;
}

.login-button:hover {
    opacity: 80%;
    transition: 0.25s;
}

.facebook {
    background-color: #3b5998;
}

.google {
    background-color: #4285f4;
}

#loading-container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255, 0.22);
}

#loading-icon {
    font-size: 66px;
    position: relative;
    left: calc(50vw - 33px);
    top: calc(50vh - 22px);
}

